
import Vue from "../../extensions/Vue";
import {Options, prop} from "vue-class-component";
import anime from "animejs";
import TodoResultData from "@/http/data/todo-result-data";
import {jobContext, unitCheckContext} from "@/store";
import UnitCheckActions from "@/store/modules/unit-check/unit-check-actions";
import JobButton from "@/components/controls/JobButton.vue";
import JobGetters from "@/store/modules/job/job-getters";
import JobData from "@/http/data/job-data";
import JobActions from "@/store/modules/job/job-actions";

class Props {
    contractId = prop({
        required: true,
        validator: (value: unknown): boolean => typeof value === "string" || value === null
    });
    todoResult = prop({
        required: true,
        type: Object
    });
}

@Options({
    name: "TodoButton",
    components: {JobButton}
})
export default class TodoButton extends Vue.with(Props) {
    declare contractId: string;
    declare todoResult: TodoResultData;

    finishUnitCheckTodoResult!: UnitCheckActions["finishUnitCheckTodoResult"];
    resetUnitCheckTodoResult!: UnitCheckActions["resetUnitCheckTodoResult"];
    selectNewJobContract!: JobActions["selectNewJobContract"];
    selectNewJobUnitCheckTodoResult!: JobActions["selectNewJobUnitCheckTodoResult"];

    requestInProgress = false;
    showControls = false;

    beforeMount(): void {
        this.finishUnitCheckTodoResult = unitCheckContext.actions.finishUnitCheckTodoResult;
        this.resetUnitCheckTodoResult = unitCheckContext.actions.resetUnitCheckTodoResult;
        this.selectNewJobContract = jobContext.actions.selectNewJobContract;
        this.selectNewJobUnitCheckTodoResult = jobContext.actions.selectNewJobUnitCheckTodoResult;
    }

    mounted(): void {
        this.$events.on("hide-todo-controls", () => this.hideControls());
    }

    get isChecked(): boolean {
        return this.todoResult.is_checked;
    }

    get jobs(): JobData[] {
        return this.todoResult.id ? this.jobsByUnitCheckTodoResultId[this.todoResult.id] : [];
    }

    get jobsByUnitCheckTodoResultId(): JobGetters["jobsByUnitCheckTodoResultId"] {
        return jobContext.getters.jobsByUnitCheckTodoResultId;
    }

    get hasIssues(): boolean {
        return this.todoResult.has_issues;
    }

    get status(): string {
        if (!this.isChecked) return "";

        return this.hasIssues ? "negative" : "positive";
    }

    displayControls(): void {
        if (this.showControls) return;

        this.$events.emit("hide-todo-controls");

        this.showControls = true;

        anime({
            targets: `#control-left-${this.uuid}`,
            translateX: ["-100%", 0],
            duration: 500,
            easing: "easeInOutSine"
        });

        anime({
            targets: `#control-right-${this.uuid}`,
            translateX: ["100%", 0],
            duration: 500,
            easing: "easeInOutSine"
        });
    }

    hideControls(): void {
        if (!this.showControls) return;

        this.showControls = false;

        anime({
            targets: `#control-left-${this.uuid}`,
            translateX: [0, "-101%"],
            duration: 500,
            easing: "easeInOutSine"
        });

        anime({
            targets: `#control-right-${this.uuid}`,
            translateX: [0, "101%"],
            duration: 500,
            easing: "easeInOutSine"
        });
    }

    async onAddJob(): Promise<void> {
        if (!this.todoResult.id) return;

        if (!this.contractId) {
            await jobContext.actions.clearSelectedNewJobContract();
        } else {
            await this.selectNewJobContract(this.contractId);
        }

        await this.selectNewJobUnitCheckTodoResult(this.todoResult.id);

        await this.$router.push({name: "JobNew"});
    }

    async onClick(): Promise<void> {
        if (this.showControls) this.hideControls();

        else this.displayControls();
    }

    async onNegativeResult(): Promise<void> {
        this.requestInProgress = true;

        await this.finishUnitCheckTodoResult({
            unitCheckTodoResult: this.todoResult,
            hasIssues: true
        });

        this.requestInProgress = false;

        await this.onAddJob();

        this.hideControls();

        this.$emit("verify");
    }

    async onPositiveResult(): Promise<void> {
        this.requestInProgress = true;

        await this.finishUnitCheckTodoResult({
            unitCheckTodoResult: this.todoResult,
            hasIssues: false
        });

        this.requestInProgress = false;

        this.hideControls();

        this.$emit("verify");
    }

    async onReset(): Promise<void> {
        if (!this.todoResult.id) return;

        this.requestInProgress = true;

        await this.resetUnitCheckTodoResult(this.todoResult.id);

        this.requestInProgress = false;

        this.hideControls();

        this.$emit("verify");
    }
}
