
import {Options} from "vue-class-component";
import Vue from "../extensions/Vue";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import UnitCheckData from "@/http/data/unit-check-data";
import {contractContext, meterContext, unitCheckContext, unitContext} from "@/store";
import _ from "lodash";
import TodoResultData from "@/http/data/todo-result-data";
import UnitData from "@/http/data/unit-data";
import TodoButton from "@/components/controls/TodoButton.vue";
import ContractMeterData from "@/http/data/contract-meter-data";
import UnitHeader from "@/components/ui/UnitHeader.vue";
import ContractData from "@/http/data/contract-data";

@Options({
    name: "UnitCheck",
    components: {
        TodoButton,
        DefaultLayout,
        UnitHeader
    },
})
export default class UnitCheck extends Vue {
    allChecksAreDone = false;
    requestInProgress = false;

    beforeMount(): void {
        this.verifyAllChecksAreDone();
    }
    
    get meters(): ContractMeterData[] {
        if (!this.selectedUnitCheck) return [];
        
        return _.filter(meterContext.state.contractMeters,
                        meter => meter.contract_id === (this.selectedUnitCheck?.contract_id as string)) ?? [];
    }

    get title(): string {
        if (!this.selectedUnitCheck) return "missing_unit_check_id";

        return _
            .chain(this.selectedUnitCheck.unit_check_type_description.split(" "))
            .map((word: string) => _.capitalize(word))
            .join(" ")
            .value();
    }

    get selectedUnitCheck(): null|UnitCheckData {
        return unitCheckContext.state.unitChecks
            .find(uc => uc.id === unitCheckContext.state.selectedUnitCheckId) as UnitCheckData;
    }
    
    get selectedContract(): null|ContractData {
        return _.find(contractContext.state.contracts, (contract: ContractData) =>
            contract.id === this.selectedUnitCheck?.contract_id) ?? null;
    }

    get todoResults(): TodoResultData[] {
        if (!this.selectedUnitCheck || !this.selectedUnitCheck.todo_results) return [];

        return this.selectedUnitCheck.todo_results;
    }

    get todoResultsByCategory(): Record<string, TodoResultData[]> {
        return _.groupBy(this.todoResults, (todoResult: TodoResultData) => todoResult.category);
    }

    get unit(): UnitData {
        return unitContext.getters.selectedUnit as UnitData;
    }

    async completeUnitCheck(): Promise<void> {
        if (!this.selectedUnitCheck) return;

        this.requestInProgress = true;

        await unitCheckContext.actions.finishUnitCheck(this.selectedUnitCheck.id);

        await this.$router.back();

        this.requestInProgress = false;
    }

    verifyAllChecksAreDone(): void {
        this.allChecksAreDone = this.todoResults.every(todoResult => todoResult.is_checked);
    }
}
